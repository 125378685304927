@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700&display=swap');

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

html {
	overflow-x: hidden !important;
}

// BODY
body {
	color: #444;
	font-size: 16px;
	font-family: 'Raleway', sans-serif;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
	background: #fff;
}

// LINKS
body a {
	color: orange;
	text-decoration: none;
	transition: 1s;
}

body a:hover,
body a:focus {
	text-decoration: underline;
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {}

h1 {
	font-size: 48px;
	margin: 0 0 20px 0;
	color: #262261;
	line-height: 1.2;
}

h2 {
	font-size: 30px;
	margin: 25px 0 20px 0;
	color: #1f71ad;
	line-height: 1.3;
	font-weight: bold;
}

h3 {
	font-size: 19px;
	margin: 25px 0 10px 0;
	padding: 3px 10px;
	background: #262261;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
	/* 4 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning {
	width: 100%;
	background: #b20000;
	color: #fff;
	overflow: hidden;
	padding: 0 0 10px 0;
	transition: 1s;
	display: block
}

#ie-warning p {
	margin: 5px auto;
}

#ie-warning a {
	color: #fff;
	text-decoration: underline;
	cursor: pointer;
}


// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
}

#social-block {
	position: absolute;
	bottom: 100px;
	right: 0;
	width: 200px;
	height: 50px;
	z-index: 999;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	position: relative;

	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 0px;
		height: 180px;
		width: 100%;
		background: rgba(255, 255, 255, .2);
		z-index: 1;
	}
}

header>.wrapper {
	position: relative;
	z-index: 90;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 30px 0 0 0;

	// @media only screen and (max-width: 990px) {
	// 	padding: 70px 0 0 0;
	// }
}

img#logo {
	float: left;
	padding-bottom: 27px;


}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS {
	position: absolute;
	right: 150px;
	top: 0px;
	border-radius: 50%;
}

#FS input {
	outline: none;
	color: #fff;
}

#FS input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {
	display: none;
}

#FS input[type=search] {
	color: transparent;
	cursor: pointer;
	background: url(/i/design/search-ic.png) no-repeat 0 center;
	padding: 0px;
	width: 20px;
	height: 20px;
	transition: all .5s;
	background-size: 20px;
}

#FS input[type=search]:focus {
	width: 200px;
	background-position: 10px center;
	background-color: rgba(255, 255, 255, .2);
	padding: 5px 5px 5px 35px;
	color: #fff;
	cursor: auto;
	font-weight: 400;
	border-radius: 0px;
}

#FS input[type=search]:focus:-moz-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input[type=search]:focus::-webkit-input-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input:-moz-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input::-webkit-input-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	position: absolute;
	right: 110px;
	top: 0px;
	overflow: hidden;
	height: 20px;
	width: 20px;
	background: url(/i/design/translate-ic.png) no-repeat;
	background-size: contain;
}

#twitter-head {
	position: absolute;
	right: 70px;
	top: 0px;
	overflow: hidden;
	height: 20px;
	width: 20px;
	background: url(/i/design/tw-ic.png) no-repeat;
	background-size: contain;
}

#facebook-head {
	position: absolute;
	right: 30px;
	top: 0px;
	overflow: hidden;
	height: 20px;
	width: 20px;
	background: url(/i/design/fb-ic.png) no-repeat;
	background-size: contain;
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_10249 #mainholder {
	width: 100%;
	padding: 0 !important;
}

#mainholder {
	// width: 990px;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	background: #fff;
	z-index: 1;
	position: relative;
}

.partnership-trust {
	position: absolute;
	right: 0;
	top: -210px;
	width: 225px;
	height: 265px;
	background: url(/i/design/partnership-tab.png) bottom center no-repeat;
	cursor: pointer;
	transition: 1s !important;
	overflow: hidden;
	background-size: contain;
	z-index: 9999999;

	&:hover {
		&:before {
			transform: rotate(90deg);
		}
	}

	&:before {
		position: absolute;
		content: '';
		width: 10px;
		height: 10px;
		background: url(/i/design/arrow-quick-links.png) no-repeat center;
		background-size: contain;
		right: 10px;
		bottom: 22px;
		z-index: 2;
		transform: rotate(-90deg);
		transition: .5s;
	}

	p {
		opacity: 0;
		color: #fff;
		width: 80%;
		margin: 10px auto;
		font-size: 14px;
		line-height: 1.1;
		transition: 1s;
		;
		margin: 40px auto 30px;
		line-height: 1.2;
	}

	a {
		color: #FAAF40;
		font-weight: bold;
		font-size: 18px;
	}
}

.trust-extended {
	transition: 1s !important;
	top: -0px;
	z-index: 9999999999999999999999999999;

	p {
		opacity: 1;
		transition: 1s !important;
	}
}

#welcome-sec {
	width: 100%;
	padding: 35px 0;
	background: #fff;

	#welcome-text {
		width: 625px;
		float: left;

		h2 {
			color: #262261;
			font-size: 40px;
			font-weight: bold;
			text-align: left;

			span {
				color: #EE4036;
				vertical-align: top;
				font-size: 25px;
				font-weight: 300;
				display: inline-block;
				margin-right: 10px;
			}
		}

		p {
			font-size: 16px;
			margin: 20px 0;
			line-height: 1.6;

			&:first-of-type {
				font-size: 18px;
			}
		}

		.read-more {
			display: block;
			width: 150px;
			height: 30px;
			background: url(/i/design/read-more-btn.jpg) no-repeat center;
			background-size: contain;
			float: right;
			margin: 40px 0 30px 0;
			transition: .5s;

			&:hover {
				background: url(/i/design/read-more-btn2.jpg) no-repeat center;
				background-size: contain;
			}
		}

		.welcome-video {
			display: block;
			width: 625px;
			height: 120px;
			background: url(/i/design/video-btn.jpg) no-repeat center;
			background-size: contain;
			text-align: center;
			line-height: 120px;
			font-size: 40px;
			color: #fff;
			float: left;
			transition: .5s;

			&:hover {
				text-decoration: none;
				text-indent: 20px;
				color: #FAAF40;
			}
		}
	}

	#facebook-container {
		float: right;
		width: 340px;
		border-top: 15px solid #262261;
		border-bottom: 15px solid #262261;
	}
}

#large-btns {
	width: 100%;
	height: auto;

	ul {
		height: auto;
		padding: 0;
		margin: 0;

		li {
			height: 250px;
			float: left;
			width: 25%;
			display: inline-block;
			padding: 0;
			line-height: 250px;
			text-align: center;
			overflow: hidden;
			margin: 0;

			a {
				font-size: 48px;

				&:hover {
					text-decoration: none;
				}
			}

			&:first-of-type {
				a {
					background: url(/i/graphics/big-btns/first-btn.jpg) no-repeat center top;
					background-size: cover;
					position: relative;
					width: 100%;
					height: 100%;
					display: block;
					color: #fff;
					z-index: 1;
					outline: 4px dotted rgba(255, 255, 255, .2);
					outline-offset: -10px;
					transition: .5s;

					&:hover {
						outline-offset: -5px;
						line-height: 200px;
					}

					&:before {
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(#262261, 0.9);
						z-index: -1;
					}
				}
			}

			&:nth-of-type(2) {
				a {
					background: url(/i/graphics/big-btns/second-btn.jpg) no-repeat center top;
					background-size: cover;
					position: relative;
					width: 100%;
					height: 100%;
					display: block;
					color: #262261;
					z-index: 1;
					outline: 4px dotted rgba(0, 0, 0, .2);
					outline-offset: -10px;
					transition: .5s;

					&:hover {
						outline-offset: -5px;
						line-height: 200px;
					}

					&:before {
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(#FAAF40, 0.85);
						z-index: -1;
					}
				}
			}

			&:nth-of-type(3) {
				a {
					background: url(/i/graphics/big-btns/third-btn.jpg) no-repeat center top;
					background-size: cover;
					position: relative;
					width: 100%;
					height: 100%;
					display: block;
					color: #fff;
					z-index: 1;
					outline: 4px dotted rgba(255, 255, 255, .2);
					outline-offset: -10px;
					transition: .5s;

					&:hover {
						outline-offset: -5px;
						line-height: 200px;
					}

					&:before {
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(#262261, 0.9);
						z-index: -1;
					}
				}
			}

			&:nth-of-type(4) {
				a {
					background: url(/i/graphics/big-btns/fourth-btn.jpg) no-repeat center top;
					background-size: cover;
					position: relative;
					width: 100%;
					height: 100%;
					display: block;
					color: #262261;
					z-index: 1;
					outline: 4px dotted rgba(0, 0, 0, .2);
					outline-offset: -10px;
					transition: .5s;

					&:hover {
						outline-offset: -5px;
						line-height: 200px;
					}

					&:before {
						position: absolute;
						content: '';
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(#FAAF40, 0.85);
						z-index: -1;
					}
				}
			}

		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 930px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {}

	h2 {}
}

//Content 2
.Content2Column {
	width: 650px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {}

	h2 {}

	tr,
	td {
		border: 1px solid #000 !important;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 30px 0 0 0;
	padding: 0;
	box-shadow: inset 0 0 0 1px #000;
	text-align: center;
}

.SideHeading:first-child {
	margin-top: 0;
}

// Heading
.SideHeading h2 {
	display: block;
	text-align: center;
	font-size: 24px;
	padding: 20px 0;
	margin: 0;
}

// List
ul.SideList {
	margin: 0 0 0 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0 0 0 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 5px 10px;
	color: #333;
	transition: 1s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	background: #999;
	color: #FFF;
	transition: 1s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;

	&:hover {
		background-color: #999;
	}
}


// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	width: 55px;
	height: 55px;
	text-align: center;
	background: #000;
	color: #fff;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 130%;

	span {
		display: block;
		font-weight: bold;
		font-size: 24px;
		margin: 10px 0 0 0;
	}
}

ul.SideList p.title {
	float: right;
	width: 75%;
	font-size: 15px;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 15px;
	padding: 0px;
	transition: 1s;

	&:hover {
		transition: 1s;
		text-decoration: none;
	}
}

//Diary
#SideHeadingDiary {}

//News
#SideHeadingNews {}

//Related pages
#SideHeadingPages {
	overflow: hidden;
	margin: 30px 0 0 0;
	background: url(/i/design/rp-bg.jpg) no-repeat center bottom;
	background-size: cover;
	outline: 2px dotted rgba(255, 255, 255, .5);
	outline-offset: -10px;

	h2 {
		color: #FAAF40;
		font-weight: 500;
		letter-spacing: 1px;
	}

	ul.SideList {
		padding: 10px 0 30px;

		li {
			a {
				padding: 5px 20px;
				font-size: 16px;
				color: #fff;

				&:hover {
					background: #1f71ad;
					text-indent: 5px;
				}
			}
		}
	}

}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}


// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	height: 80px;
	overflow: hidden;
	background: #fff;

	.CMSawards {
		overflow: hidden;
		width: 990px;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}

}


// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	clear: both;
	height: 180px;
	padding: 60px 0;
	width: 50%;
	position: relative;
	display: inline-block;
	background: #262261;
	position: relative;
	outline: 4px dotted rgba(255, 255, 255, .2);
	outline-offset: -10px;
	float: left;
	overflow: hidden;

	&:before {
		position: absolute;
		content: '';
		right: 0;
		top: -100px;
		background: url(/i/design/footer-logo.png) no-repeat center;
		width: 600px;
		height: 600px;
		background-size: contain;
		z-index: 0;
		opacity: .5;
	}
}

footer>.wrapper {
	color: #fff;
	text-align: left;
	width: 50%;
	float: right;
	font-size: 18px;
}

footer>.wrapper p {
	display: block;
	margin-left: 100px;

	span {
		color: #FAAF40;
		display: block;
	}
}

footer>.wrapper a {
	color: #fff;
	margin-left: 100px;
}


#map {
	display: inline-block;
	width: 50%;
	position: relative;
	right: 0;
	bottom: 0;
	float: right;
	overflow: hidden;
}

p.address {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		background: url(/i/design/address-ic.png) no-repeat center;
		width: 20px;
		height: 30px;
		background-size: contain;
		left: -40px;
		top: 30px;
	}
}

p.telephone {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		background: url(/i/design/telephone-ic.png) no-repeat center;
		width: 20px;
		height: 20px;
		background-size: contain;
		left: -40px;
		top: 0px;
	}
}

footer a[href^="mailto:"] {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		background: url(/i/design/mail-ic.png) no-repeat center;
		width: 20px;
		height: 20px;
		background-size: contain;
		left: -40px;
		top: 0px;
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 30px;
	width: 100%;
	line-height: 30px;
	background: #262261;
	overflow: hidden;
	position: relative;

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #FAAF40;
	}

	#credit {
		float: right;
	}
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #000;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #1f71ad;
$blockquote-border-color: #1f71ad;
$blockquote-color: #fff;
$blockquote-speech-mark-color: #fff;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}